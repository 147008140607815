<template>
  <div class="container space-2">
    <div class="w-md-80 w-lg-70 text-center mx-md-auto mb-9">
      <span class="d-block small font-weight-bold text-cap mb-2">Organizers</span>
      <h2>{{ list.title }} is Powered by</h2>
    </div>

    <div class="row justify-content-lg-center">
      <div v-for="item in list.organiser" :key="item.image_url" class="mb-9 mb-md-0" :class="(list.organiser.length === 2 ? 'col-md-6' : 'col-md-4')">
        <div class="d-block text-center" :class="item.css">
          <h4 class="mb-2">{{ item.post }}</h4>
          <img style="max-width:12rem" :src="require('@/assets/img/' + list.image_path + '/organizers/' + item.image_url)">
        </div>
      </div>
      <!-- <div class="col-md-4 mb-9 mb-md-0" data-aos="fade-up" data-aos-delay="150">
        <div class="d-block text-center column-divider-md column-divider-20deg px-md-3" href="#">
          <div class="d-block text-center">
            <h4 class="mb-2">Lead Partner</h4>
            <img style="max-width:12rem" src="@/assets/img/events/apiecobooster/organisers/hsbc.png">
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-md-0" data-aos="fade-up" data-aos-delay="200">
        <div class="d-block text-center column-divider-md column-divider-20deg px-md-3">
          <div class="d-block text-center">
            <h4 class="mb-2">Technology Partner</h4>
            <img style="max-width:12rem" src="@/assets/img/events/apiecobooster/organisers/benovelty.png">
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="w-lg-80 mx-auto">
      <div class="row justify-content-center align-items-center mt-9 text-center">
        <div class="col-12 text-center mb-2">
          <h4>Supporting Organizations</h4>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <img style="max-width:10rem" src="@/assets/img/apidays-2023/organizers/hkcs.png">
        </div>
        <div class="col-12 col-md-4 mb-3">
          <img style="max-width:10rem" src="@/assets/img/apidays-2023/organizers/dala.png">
        </div>
        <div class="col-12 col-md-4 mb-3">
          <img style="max-width:10rem" src="@/assets/img/apidays-2023/organizers/ifta.png">
        </div>
        <div class="col-12 col-md-4 mb-5 mb-md-0">
          <img style="max-width:10rem" src="@/assets/img/apidays-2023/organizers/hkstp-saa.png">
        </div>
        <div class="col-12 col-md-4 mb-3 mb-md-0">
          <img style="max-width:10rem" src="@/assets/img/apidays-2023/organizers/isoc.png">
        </div>
        <div class="col-12 col-md-4 mb-3 mb-md-0">
          <img style="max-width:10rem" src="@/assets/img/apidays-2023/organizers/openapihub.png">
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>

export default {
  name: 'OrganiserBar',
  components: {
  },
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  computed: {
    // sortedItems() {
    //   return orderBy(this.items, 'displayOrder')
    // }
  }
}
</script>
<style lang="scss" scoped>
// @import '~slick-carousel/slick/slick.css';
</style>
